import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { GuestGuard } from 'src/app/core/guards/guest.guard';
import { FeatureModuleComponent } from 'src/app/feature-module/feature-module.component';

const routes: Routes = [
  {
    path: '',
    component: FeatureModuleComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: 'admin',
      //   pathMatch: 'full',
      // },
      {
        path: 'admin',
        canLoad: [AdminGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('src/app/feature-module/admin/admin.module').then(
            (m) => m.AdminModule
          ),
      },
      {
        path: '',
        canLoad: [GuestGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () =>
          import('src/app/feature-module/auth/auth.module').then(
            (m) => m.AuthModule
          ),
      },
      { redirectTo: '', path: '**' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeatureModuleRoutingModule {}
