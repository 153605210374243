import { Injectable } from '@angular/core';
import swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SwalService {
  constructor() {}

  public error(error: any): void {
    let status: number = !!error && !!error.status ? error.status : null;

    switch (status) {
      case 401:
        this.error401(error);
        break;

      case 422:
        this.error422(error);
        break;

      case 503:
        this.error503(error);
        break;

      default:
        this.unknownError(error);
        break;
    }
  }

  public error401(error: any): void {
    let message = !!error.error ? error.error.message : 'Unknown Error';

    swal.fire({
      title: '¡Error!',
      html: `<b>${message}</b>`,
      icon: 'error',
    });
  }

  public error422(error: any): void {
    console.log('SwalService error422 error', error);

    let message = '<div><b>';

    let errors: any =
      !!error.error && !!error.error.errors && !!error.error.errors
        ? error.error.errors
        : {};

    Object.entries(errors).forEach((error: any) => {
      console.log('error422 error', error);
      message += error[1];
      message += '<br>';
    });

    message += '</b></div>';

    console.log('message', message);

    swal.fire({
      title: '¡Error!',
      html: message,
      icon: 'error',
    });
  }

  public error503(error: any): void {
    let message = !!error.error ? error.error.message : 'Unknown Error';

    swal.fire({
      title: 'Maintenance mode',
      html: `<b>${message}</b>`,
      icon: 'warning',
    });
  }

  public unknownError(error: any): void {
    let message = !!error.error ? error.error.message : 'Unknown Error';

    swal.fire({
      title: '¡Error!',
      html: `<b>${message}</b>`,
      icon: 'error',
    });
  }

  public notify(title: string, message: string, icon: SweetAlertIcon): void {
    swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }
}
