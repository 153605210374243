import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureModuleRoutingModule } from 'src/app/feature-module/feature-module-routing.module';
import { FeatureModuleComponent } from 'src/app/feature-module/feature-module.component';
import { SharedModule } from 'src/app/shared/module/shared.module';

@NgModule({
  declarations: [FeatureModuleComponent],
  imports: [CommonModule, FeatureModuleRoutingModule, SharedModule],
})
export class FeatureModuleModule {}
