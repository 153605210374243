import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/modules/modules.module').then((m) => m.ModulesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule, NgxLoadingModule],
})
export class AppRoutingModule {}
