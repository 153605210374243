import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-module',
  templateUrl: './feature-module.component.html',
  styleUrls: ['./feature-module.component.css'],
})
export class FeatureModuleComponent implements OnInit, OnDestroy {
  constructor() {
    console.log('FeatureModuleComponent constructor');
  }

  ngOnInit(): void {
    console.log('FeatureModuleComponent ngOnInit');
  }

  ngOnDestroy(): void {
    console.log('FeatureModuleComponent ngOnDestroy');
  }
}
