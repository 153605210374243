import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SwalService } from 'src/app/services/swal.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad {
  constructor(
    private _api: ApiService,
    private _router: Router,
    private _swal: SwalService
  ) {
    console.log('AdminGuard constructor');
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._api.post('api/sanctum/user', {}).pipe(
      map((response: { user: boolean }) => {
        console.log('AdminGuard canLoad response', response);

        let user: any = response.user;

        if (!user) {
          Swal.fire({
            title: `Error`,
            html: `Unauthenticated`,
            icon: `error`,
          });

          this._router.navigate(['/']);

          return false;
        }

        if (!!user.is_superadmin) {
          this._router.navigate([environment.superadmin_module]);

          return false;
        }

        if (!user.is_admin && !user.is_doctor) {
          Swal.fire({
            title: `Error`,
            html: `You do not have sufficient permissions to access the requested module`,
            icon: `error`,
          });

          this._router.navigate(['/']);

          return false;
        }

        this._api.userEmitter.next(user);
        this._api.userEmitter.complete();

        return !!user;
      }),
      catchError((error: any) => {
        console.log('AdminGuard catchError error', error);

        this._swal.error(error);

        return of(true);
      })
    );
  }
}
